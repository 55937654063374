import { Helmet } from "react-helmet";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";
import Service from "../components/Service";
import GetSiteData from "../sitedata";
import Smalltitle from "../components/Smalltitle";
import Resume from "../components/Resume";

function About() {
  const { information } = GetSiteData();
  const { services } = GetSiteData();
  // const {reviews} = GetSiteData()
  const { experience } = GetSiteData();
  const { workingExperience } = experience;
  return (
    <Layout>
      <Helmet>
        <title>About - Kuzar.fi</title>
        <meta name="description" content="About page | Kuzar.fi" />
      </Helmet>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="About Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-about-image">
                <ProgressiveImage
                  src={information.aboutImage}
                  placeholder="/images/about-image-placeholder.png"
                >
                  {(src) => <img src={src} alt="aboutimage" />}
                </ProgressiveImage>
                <span className="mi-about-image-icon"></span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-about-content">
                <h3>
                  I am <span className="color-theme">{information.name}</span>
                </h3>
                <p>
                  I am a software engineer currently working in space industry.
                  Working mostly in web development and software in general. ARM
                  certificate engineer. FPGA enthusiast, curious about new
                  technology. Interested about acceleration in 3D rendering and
                  data processing field as hobby. Electrical Automation
                  (PLC/Embedded System) educational background.
                </p>
                <ul>
                  {!information.name ? null : (
                    <li>
                      <b>Full Name</b> {information.name}
                    </li>
                  )}
                  {!information.phone ? null : (
                    <li>
                      <b>Phone</b> {information.phone}
                    </li>
                  )}
                  {!information.nationality ? null : (
                    <li>
                      <b>Nationality</b> {information.nationality}
                    </li>
                  )}
                  {!information.language ? null : (
                    <li>
                      <b>Languages</b> {information.language}
                    </li>
                  )}
                  {!information.email ? null : (
                    <li>
                      <b>Email</b> {information.email}
                    </li>
                  )}
                  {!information.address ? null : (
                    <li>
                      <b>Address</b> {information.address}
                    </li>
                  )}
                  {!information.hobbies ? null : (
                    <li>
                      <b>Hobbies</b> {information.hobbies}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Skill Sets" />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
              {services.map((service) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mt-30"
                  style={{
                    height: "200",
                  }}
                  key={service.title}
                >
                  <Service content={service} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Resume" />
          <Smalltitle title="Working Experience" icon="briefcase" />
          <div className="mi-resume-wrapper">
            {workingExperience.map((workingExp) => (
              <Resume key={workingExp.id} {...workingExp} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
