import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// @ts-ignore
import LineIcon from "react-lineicons";
import Head3d from "./Head3d";

function Header() {
  const [navigationToggler, setNavigationToggler] = useState(false);
  const handleNavigationToggler = () => {
    setNavigationToggler(!navigationToggler);
  };

  const Avatar = () => {
    return <Head3d rotate={false} />;
  };

  const Memorized3DAvatar = React.memo(Avatar);

  return (
    <nav className={navigationToggler ? "mi-header is-visible" : "mi-header"}>
      <button onClick={handleNavigationToggler} className="mi-header-toggler">
        {!navigationToggler ? (
          <LineIcon name="menu" />
        ) : (
          <LineIcon name="close" />
        )}
      </button>
      <div className="mi-header-inner">
        <div className="mi-header-image">
          <Memorized3DAvatar />
        </div>

        <ul className="mi-header-menu">
          <li>
            <NavLink exact to="/">
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/about">
              <span>About</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact">
              <span>Contact</span>
            </NavLink>
          </li>
        </ul>
        <p className="mi-header-copyright">
          &copy; {new Date().getFullYear()}{" "}
          <b>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://kuzar.fi"
            >
              Kuzar.fi
            </a>
          </b>
        </p>
      </div>
    </nav>
  );
}

export default Header;
