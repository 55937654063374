import React from "react";

interface Props {
  title: string;
}

function Sectiontitle(props: Props) {
  return (
    <div className="mi-sectiontitle">
      <h2>{props.title}</h2>
      <span>{props.title}</span>
    </div>
  );
}
export default Sectiontitle;
