export interface SocialLinks {
  facebook: string;
  twitter: string;
  pinterest: string;
  behance: string;
  linkedin: string;
  dribbble: string;
  github: string;
}

export interface Information {
  name: string;
  aboutContent: string;
  age: number;
  phone: string;
  nationality: string;
  language: string;
  email: string;
  address: string;
  freelanceStatus: string;
  socialLinks: SocialLinks;
  brandImage: string;
  aboutImage: string;
  aboutImageLg: string;
  cvfile: string;
  hobbies: string;
  recaptcha: string;
}

export interface Service {
  title: string;
  icon: string;
  details: string;
}

export interface Author {
  name: string;
  designation: string;
}

export interface Review {
  id: number;
  content: string;
  author: Author;
}

export interface Skill {
  title: string;
  value: number;
}

export interface Portfolio {
  id: number;
  title: string;
  subtitle: string;
  imageUrl: string;
  largeImageUrl: string[];
  url: string;
}

export interface WorkingExperience {
  id: number;
  year: string;
  position: string;
  company: string;
  companyLink?: string;
  details: string;
}

export interface EducationExperience {
  id: number;
  year: string;
  graduation: string;
  university: string;
  details: string;
}

export interface Experience {
  workingExperience: WorkingExperience[];
  educationExperience: EducationExperience[];
}

export interface Blog {
  id: number;
  title: string;
  featuredImage: string;
  filesource: string;
  createDay: string;
  createMonth: string;
  createYear: string;
}

export interface ContactInfo {
  phoneNumbers: string[];
  emailAddress: string[];
  address: string;
}

export interface SiteDataModel {
  information: Information;
  services: Service[];
  reviews: Review[];
  skills: Skill[];
  portfolios: Portfolio[];
  experience: Experience;
  blogs: Blog[];
  contactInfo: ContactInfo;
}

export interface ContactForm {
  name: string;
  email: string;
  subject: string;
  message: string;
  recaptcha: string;
}

const GetSiteData = (): SiteDataModel => {
  return {
    information: {
      name: "Kuzar Kyzyrbek",
      aboutContent:
        "Space industry worker / Software engineer / React.js / Python / AWS / Web developer",
      age: 30,
      phone: "",
      nationality: "Kazakhstan",
      language: "Kazakh, English, Chinese",
      email: "kuzar@kuzar.fi",
      address: "Helsinki metropolitan area",
      freelanceStatus: "Available",
      socialLinks: {
        facebook: "https://www.facebook.com/kkuzar",
        twitter: "",
        pinterest: "",
        behance: "",
        linkedin: "https://www.linkedin.com/in/kuzar-kyzyrbek",
        dribbble: "",
        github: "https://github.com/kkuzar",
      },
      brandImage: "/images/avatar.png",
      aboutImage: "/images/about-image.jpg",
      aboutImageLg: "/images/about-image-lg.jpg",
      cvfile: "/files/empty.pdf",
      hobbies: "Music, Photography, Gaming, Electronic crafting",
      recaptcha: "6LflUWIcAAAAAPJnfNYvdBMnh9shmHXXZHcqItHQ",
    },
    services: [
      {
        title: "Python",
        icon: "python",
        details:
          "Working with python most in web development, scripting, machine learning in production environment. ",
      },
      {
        title: "PHP",
        icon: "php",
        details:
          "Rich experience in PHP web development. Fully understand the mechanism of PHP and working with a variety of framework such as laravel, symfony and etc.",
      },
      {
        title: "React.js",
        icon: "react",
        details:
          "Understand react working mechanism. Have experience with react.js in production environment. ",
      },
      {
        title: "C/C++",
        icon: "code",
        details:
          "Have experience embedded C language in bare metal ARM chipset development. Using KEIL solutions.",
      },
      {
        title: "Linux",
        icon: "code",
        details:
          "Have experience with linux system and development. Linux kernel transplant for embedded system.",
      },
      {
        title: "Mobile Application",
        icon: "apple",
        details:
          "Have experience with Objective-C development in Apple products.",
      },
    ],
    reviews: [],
    skills: [],
    portfolios: [
      {
        id: 1,
        title: "T-shirt Mockup",
        subtitle: "A beautiful t-shirt mockup.",
        imageUrl: "/images/portfolio-image-1.jpg",
        largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
        url: "https://dribbble.com",
      },
    ],
    experience: {
      workingExperience: [
        {
          id: 1,
          year: "2021.1 - Present",
          position: "Software Engineer",
          company: "ICEYE Oy",
          companyLink: "https://www.iceye.com/",
          details:
            "Working as software engineer relate to satellite communications in ICEYE software team.",
        },
        {
          id: 2,
          year: "2020.5 - 2020.12",
          position: "Full-stack Developer",
          company: "Noiseless Acoustics Oy",
          companyLink: "https://nlacoustics.com/",
          details:
            "Working as web developer in Cloud-Solution team for Noiseless Acoustics Oy.",
        },
        {
          id: 3,
          year: "2018.2 - 2020.4",
          position: "Web Developer",
          company: "LeadDesk Oyj",
          companyLink: "https://leaddesk.com/",
          details:
            "Working in professional customer service team as web developer in LeadDesk oyj.",
        },
        {
          id: 4,
          year: "2017.10 - 2018.2",
          position: "Web Developer",
          company: "WordDive Ltd.",
          companyLink: "https://www.worddive.com/en/",
          details:
            "Working as web developer providing redis caching solutions for WordDive ltd.",
        },
      ],
      educationExperience: [],
    },
    blogs: [],
    contactInfo: {
      phoneNumbers: [],
      emailAddress: ["kuzar@kuzar.fi"],
      address: "Helsinki metropolitan area / Nur-Sultan , Kazakhstan.",
    },
  };
};

export default GetSiteData;
