import { Helmet } from "react-helmet";
import React from "react";
import * as Yup from "yup";
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import GetSiteData from "../sitedata";
import { Formik } from "formik";
import Recaptcha from "react-recaptcha";
import axios from "axios";

import ReactGa from 'react-ga';

import { ContactForm } from "../sitedata";
import { useSnackbar } from 'react-simple-snackbar'

const ContactValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name field too short!")
    .max(50, "Name field is too long!")
    .required("contact name is required"),
  email: Yup.string()
    .email("Invalid email format!")
    .max(100, "email is too long")
    .required("Email field is required"),
  subject: Yup.string()
    .min(1, "Subject cannot be empty.")
    .max(128, "Subject is too long.")
    .required("Subject is required!"),
  message: Yup.string()
    .min(1, "Message cannot be empty.")
    .max(1000, "Message is too long.")
    .required("Message is required!"),
  recaptcha: Yup.string().required("Recaptcha is required!"),
});

function Contact() {
  const { contactInfo } = GetSiteData();
  const { phoneNumbers, emailAddress, address } = contactInfo;

  const [openError] = useSnackbar({
      position: 'top-center',
      style: {
          backgroundColor: 'red',
          border: '2px solid red',
          color: 'white',
          fontFamily: 'Menlo, monospace',
          fontSize: '20px',
          textAlign: 'center',
      },
      closeStyle: {
          color: 'lightcoral',
          fontSize: '16px',
      },
  })
  const [openSuccess] = useSnackbar({
      position: 'top-center',
      style: {
          backgroundColor: 'green',
          border: '2px solid lightgreen',
          color: 'white',
          fontFamily: 'Menlo, monospace',
          fontSize: '20px',
          textAlign: 'center',
      },
      closeStyle: {
          color: 'lightcoral',
          fontSize: '16px',
      },
  })

  React.useEffect(() => {
    ReactGa.initialize('UA-108127916-2', { standardImplementation: true });
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  const { information } = GetSiteData();
  const { recaptcha } = information;
  const defaultContactform: ContactForm = {
    name: "",
    email: "",
    subject: "",
    message: "",
    recaptcha: "",
  };

  return (
    <Layout>
      <Helmet>
        <title>Contact - Kuzar.fi</title>
        <meta name="description" content="Contact page | Kuzar.fi" />
      </Helmet>
      <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Contact Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className={"mi-contact-formwrapper"}>
                <h4>Get In Touch</h4>
                <Formik
                  initialValues={defaultContactform}
                  onSubmit={(values: ContactForm, actions) => {
                    setTimeout(() => {
                      actions.setSubmitting(false);

                      ReactGa.event({
                          category: "Submit",
                          action: "Submit button clicked from contact form.",
                      });

                      axios
                        .post(
                          "https://europe-west1-kuzar-fi-5dfc0.cloudfunctions.net/kuzarfi/sendmail",
                          values
                        )
                        .then((res) => {
                            openSuccess("Email sent!",3300);
                            ReactGa.event({
                                category: "Submit",
                                action: "Submit success",
                            });
                        })
                        .catch((err) => {
                          console.log(err);
                            ReactGa.event({
                                category: "Submit",
                                action: "Submit error",
                            });
                            openError("Email sending failed!", 3200);
                        });
                    }, 200);
                  }}
                  validationSchema={ContactValidationSchema}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                    submitForm,
                    setFieldValue,
                  }) => (
                    <>
                      <div className="mi-form-field">
                        <label htmlFor="contact-form-name">
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleChange}
                          type="text"
                          name="name"
                          id="contact-form-name"
                          value={values.name}
                        />
                        {errors.name && touched.name ? (
                          <small
                            style={{ color: "red" }}
                            className="form-text d"
                          >
                            {" "}
                            {errors.name}
                          </small>
                        ) : (
                          <small className="form-text text-muted">
                            Name is required, max length of name is 50
                          </small>
                        )}
                      </div>
                      <div className="mi-form-field">
                        <label htmlFor="contact-form-email">
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleChange}
                          type="text"
                          name="email"
                          id="contact-form-email"
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <small
                            style={{ color: "red" }}
                            className="form-text d"
                          >
                            {" "}
                            {errors.email}
                          </small>
                        ) : (
                          <small className="form-text text-muted">
                            Plase input email address
                          </small>
                        )}
                      </div>
                      <div className="mi-form-field">
                        <label htmlFor="contact-form-subject">
                          Subject <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={handleChange}
                          type="text"
                          name="subject"
                          id="contact-form-subject"
                          value={values.subject}
                        />
                        {errors.subject && touched.subject ? (
                          <small
                            style={{ color: "red" }}
                            className="form-text d"
                          >
                            {" "}
                            {errors.subject}
                          </small>
                        ) : (
                          <small className="form-text text-muted">
                            Max length of subject is 100
                          </small>
                        )}
                      </div>
                      <div className="mi-form-field">
                        <label htmlFor="contact-form-message">
                          Message <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          onChange={handleChange}
                          name="message"
                          id="contact-form-message"
                          cols={30}
                          rows={6}
                          value={values.message}
                        ></textarea>
                        {errors.message && touched.message ? (
                          <small
                            style={{ color: "red" }}
                            className="form-text d"
                          >
                            {" "}
                            {errors.message}
                          </small>
                        ) : (
                          <small className="form-text text-muted">
                            Please make sure the message is within 1000 chars
                          </small>
                        )}
                      </div>
                      <Recaptcha
                        sitekey={recaptcha}
                        render="explicit"
                        theme="dark"
                        verifyCallback={(response) => {
                          setFieldValue("recaptcha", response);
                        }}
                        onloadCallback={() => {}}
                      />
                      {errors.recaptcha && touched.recaptcha ? (
                        <small style={{ color: "red" }} className="form-text">
                          {" "}
                          {errors.recaptcha}
                        </small>
                      ) : (
                        <small className="form-text text-muted"></small>
                      )}
                      <div className="mi-form-field">
                        <button
                          className="mi-button"
                          type="submit"
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          Send Mail
                        </button>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-contact-info">
                {!emailAddress ? null : (
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.Mail />
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>Email</h6>
                      {emailAddress.map((email) => (
                        <p key={email}>
                          <a href={`mailto:${email}`}>{email}</a>
                        </p>
                      ))}
                    </div>
                  </div>
                )}
                {!phoneNumbers ? null : (
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.MapPin />
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>Address</h6>
                      <p>{address}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
