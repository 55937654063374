import React from "react";
import { Helmet } from "react-helmet";
import Particles from "react-particles-js";
import Layout from "../components/Layout";
import Socialicons from "../components/Socialicons";
import GetSiteData from "../sitedata";

// @ts-ignore
import LineIcon from "react-lineicons";

function Home({ lightMode }) {
  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#ffffff",
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
  };

  const paramConfigLight = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#000000",
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
  };

  const { information } = GetSiteData();
  const particals = lightMode ? paramConfigLight : paramConfig;
  const backBlockColorStyle = lightMode
    ? { backgroundColor: "rgba(255,255,255,0.75)" }
    : { backgroundColor: "rgba(0,0,0,0.95)" };

  const videoArr = [
    "/assets/Blue.mp4",
    "/assets/Glitch.mp4",
    "/assets/SourceCode.mp4",
  ];
  const currVideo = videoArr[Math.floor(Math.random() * videoArr.length)];

  return (
    <Layout>
      <Helmet>
        <title>Home - Kuzar.fi</title>
        <meta name="description" content="Personal Website of Kuzar Kyzyrbek" />
      </Helmet>
      <video
        autoPlay
        muted
        loop
        id="homebg"
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "50%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          // @ts-ignore
          zIndex: "-1",
        }}
      >
        <source src={currVideo} type="video/mp4" />
      </video>

      <div className="mi-home-area mi-padding-section">
        <Particles
          className="mi-home-particle"
          // @ts-ignore
          params={particals}
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12" style={backBlockColorStyle}>
              <div className="mi-home-content">
                <h1>
                  Hi, I am{" "}
                  <span className="color-theme">{information.name}</span>
                </h1>
                <p>{information.aboutContent}</p>

                <ul className={"mi-socialicons"} style={{ fontSize: 25 }}>
                  <li>
                    <LineIcon name="python" />
                  </li>
                  <li>
                    <LineIcon name="react" />
                  </li>
                  <li>
                    <LineIcon name="php" />
                  </li>
                  <li>
                    <LineIcon name="javascript" />
                  </li>
                  <li>
                    <LineIcon name="laravel" />
                  </li>
                  <li>
                    <LineIcon name="apple" />
                  </li>
                </ul>

                <Socialicons bordered />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
