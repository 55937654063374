import React from "react";
import { WorkingExperience } from "../sitedata";

function Resume(props: WorkingExperience) {
  const { year, position, company, companyLink, details } = props;
  const jump = companyLink ? companyLink : "#";
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position}</h5>
        <a href={jump} target="_blank">
          <h6 className="mi-resume-company">{company}</h6>
        </a>
        <p>{details}</p>
      </div>
    </div>
  );
}

// @ts-ignore
export default Resume;
