import React, { Suspense } from "react";
import { Engine, Scene, Model } from "react-babylonjs";
import "@babylonjs/loaders/glTF";
import { Vector3, Color4 } from "@babylonjs/core";
import "babylonjs-inspector";

interface Props {
  rotate: boolean;
}

const Head3d = (props: Props) => {
  // const [loading, setLoading] = React.useState(false);
  const { rotate } = props;

  return (
    <>
      <Engine
        antialias
        adaptToDeviceRatio
        // engineOptions={{ preserveDrawingBuffer: true, stencil: true }}
        canvasId="head3d"
        canvasStyle={{
          width: "100%",
          height: "100%",
        }}
      >
        {/*@ts-ignore*/}
        <Scene clearColor={new Color4(0, 0, 0, 0)}>
          <arcRotateCamera
            name="camera"
            alpha={0}
            beta={0}
            radius={10}
            target={Vector3.Zero()}
            setPosition={[new Vector3(80, 80, 300)]}
            lowerBetaLimit={0.01}
            upperBetaLimit={(Math.PI / 2) * 0.99}
            lowerRadiusLimit={20}
            useAutoRotationBehavior={rotate}
          />
          <hemisphericLight
            name="light1"
            intensity={1.7}
            direction={Vector3.Right()}
          />

          <Suspense
            fallback={
              <box name="fallback" position={new Vector3(-2.5, 0, 0)} />
            }
          >
            {/*@ts-ignore*/}
            <Model
              rootUrl={`/assets/`}
              sceneFilename="head3d.glb"
              scaleToDimension={220.0}
              // data={rawGlb}
              pluginExtension=".glb"
              position={new Vector3(0, 0, 0)}
              onModelError={(err) => {
                console.error(err, "<-- error");
              }}
              // onModelLoaded={(e) => {
              //     console.log(e, "<-- loaded")
              //     setLoading(()=> true);
              //     console.log(loading);
              // }}
              // onLoadProgress={(e)=> {
              //     console.log(e, "<-- loading")
              //     console.log(loading);
              // }}
            ></Model>
          </Suspense>
        </Scene>
      </Engine>
    </>
  );
};

export default Head3d;
